<template>
  <div
    class="mapChart"
    id="myChart"
    :style="{ width: '100%', height: '100%' }"
  ></div>
</template>

<script>
import * as echarts from "echarts";
// 引入请求方法
import { fun_GetAction } from "@/api";
// 引入中国地图JSON数据
import "@/utils/china.js"; // 引入中国地图数据
export default {
  data: () => {
    return {
      chart: null,
      option: {
        // 鼠标悬浮提示框
        tooltip: {
          trigger: "item",
          backgroundColor: "opacity"
        },
        geo: {
          map: "china",
          roam: "scale", // 是否开启鼠标缩放和平移漫游
          zoom: 1.2, // 视角缩放比例
          silent: true, // 禁止鼠标触发
          label: {
            normal: {
              show: true,
              fontSize: "10",
              color: "#02606F"
            }
          },
          itemStyle: {
            normal: {
              borderWidth: 1, // 区域边框宽度
              borderColor: "#5A92D1", // 区域边框颜色
              areaColor: "#CCE4FF" // 区域颜色
            },
            emphasis: {
              areaColor: "#f2d5ad",
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              borderWidth: 0
            }
          },
          scaleLimit: {
            //所属组件的z分层，z值小的图形会被z值大的图形覆盖
            min: 1, //最小的缩放值
            max: 2.5 //最大的缩放值
          },
          // 隐藏南海诸岛
          regions: [
            {
              name: "南海诸岛",
              value: 0,
              itemStyle: {
                normal: {
                  opacity: 0
                }
              },
              label: {
                show: false
              }
            }
          ]
        },
        //左侧小导航图标
        visualMap: {
          min: 0,
          max: 1,
          show: false,
          inRange: {
            color: ["#CCE4FF", "#6FADF2"]
          }
        },
        series: [
          {
            name: "省份",
            type: "map",
            map: "china",
            geoIndex: 0,
            label: {
              normal: {
                show: true
              }
            },
            data: []
          }
        ]
      }
    };
  },
  mounted() {
    this.fun_GetProvinceData();
    window.onresize = () => {
      //每次改变窗口调用一次resize()
      this.chart.resize();
    };
  },
  // 组件销毁之前
  fun_BeforeDestroy() {
    // 判断是否初始化成功
    if (!this.chart) {
      // 不成功则返回
      return;
    }
    // 销毁el
    this.chart.dispose();
    // 销毁el
    this.chart = null;
  },
  methods: {
    /**
     * @author 滕威
     * @description 初始化
     */
    fun_Init() {
      this.$nextTick(() => {
        this.chart = echarts.init(document.getElementById("myChart"));
        // 设置echarts选项
        this.chart.setOption(this.option);
      });
    },
    /**
     * @author 滕威
     * @description 获取省份交易中心数量
     */
    fun_GetProvinceData() {
      fun_GetAction("/bidOpenInfo/getProvinceData").then(res => {
        const { result, data } = res.data;
        if (result) {
          // 绑定到实例
          let dataList = data.map(v => {
            return { name: v.provinceName, value: v.tradeCenterNum };
          });
          this.option.series[0].data = dataList;
          this.fun_Init();
        }
      });
    }
  }
};
</script>
