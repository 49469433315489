// 引入请求方法
import { fun_GetAction, fun_PostAction } from "@/api";
// 引入地图组件
import MapChart from "./charts/mapChart.vue";
// 默认导出
export default {
  // data
  data: () => {
    return {
      // form对象
      form: {
        cityCount: null, // 省
        provinceCount: null, // 地市
        tranderCount: null // 交易中心
      },
      // 下拉框选中的值
      platformProvince: "420000",
      // 交易中心列表数据
      tableData: [],
      // 我的项目列表数据
      data: [],
      // 我的项目表头数据
      tableColumns: [
        {
          label: "招标项目名称",
          prop: "projectName"
        },
        {
          label: "招标项目编号",
          prop: "projectNo"
        },
        {
          label: "标段（包）名称",
          prop: "bidSectionName"
        },
        {
          label: "标段（包）编号",
          prop: "bidSection"
        },
        {
          label: "开标开始时间",
          prop: "meetingStartDate"
        },
        {
          label: "开标进度",
          width: "90",
          slotName: "stateName"
        },
        {
          label: "操作",
          slotName: "op"
        }
      ],
      // 分页
      reqPageData: {
        platformCode: null, // platformCode
        current: 1, // 页码
        size: 2 // 每页条数
      },
      total: 0 // 总条数
    };
  },
  // 注册组件
  components: {
    MapChart // 地图组件
  },
  // 模板编译完成
  mounted() {
    // 查询当前登录人的项目
    this.fun_GetTableList();
    // 获取全国交易中心数量
    this.fun_GetProvinceCityCount();
    // 显示交易中心列表
    this.fun_ChangeProvince({ code: "420000" });
  },
  // 自定义方法
  methods: {
    /**
     * @author 滕威
     * @description 查询当前登录人的项目
     */
    async fun_GetTableList() {
      // 获取platformCode
      this.reqPageData.platformCode = sessionStorage.getItem("platformCode");
      // 请求获取res
      let res = await fun_PostAction(
        "bidOpenInfo/getCorpBidInfoList",
        this.reqPageData
      );
      // 解构res.data
      const { result, data, msg } = res.data;
      // 判断请求是否成功
      if (result) {
        // 回显列表
        this.data = data.records;
        // 回显总条数
        this.total = data.total;
      } else {
        // 请求失败，提示错误信息
        if (msg) this.$message.error(msg);
      }
    },
    /**
     * @author 滕威
     * @description 选择交易中心所在省市
     */
    fun_ChangeProvince(e) {
      // 获取选中的省市
      let platformProvince = e.code;
      // 请求获取res
      fun_GetAction(
        "bidOpenInfo/getTradeCenter?platformProvince=" + platformProvince
      ).then(res => {
        // 解构res.data
        const { result, data, msg } = res.data;
        // 判断请求是否成功
        if (result) {
          // 回显列表
          this.tableData = data;
        } else {
          // 请求失败，提示错误信息
          if (msg) this.$message.error(msg);
        }
      });
    },
    /**
     * @author 滕威
     * @description 获取全国交易中心数量
     */
    async fun_GetProvinceCityCount() {
      // 请求获取res
      let res = await fun_GetAction("bidOpenInfo/getProvinceCityCount");
      // 解构res.data
      const { result, data, msg } = res.data;
      // 判断请求是否成功
      if (result) {
        // 回显交易中心数量
        this.form = data;
      } else {
        // 请求失败，提示错误信息
        if (msg) this.$message.error(msg);
      }
    },
    /**
     * @author 滕威
     * @description 进入项目一览
     */
    fun_GetInto(data) {
      // 进入项目一览
      this.$router.push({
        path: "/tradingCenterList",
        query: {
          tradeCenterNo: data.platformCode,
          platformName: data.platformName
        }
      });
    },
    /**
     * @author 滕威
     * @description 进入项目详情
     */
    fun_GetInfoDetail(data) {
      // 进入详情页
      this.$router.push({
        path: "/tradingCenterDetail",
        query: { id: data.id, platformName: data.tradeCenter }
      });
    },
    /**
     * 改变每页条数执行的函数
     * @params e 每页条数
     * @author 滕威
     */
    fun_LimitChange(e) {
      // 获取每页显示条数
      this.reqPageData.size = e;
      // 获取一览表格数据
      this.fun_GetTableList();
    },
    /**
     * 改变页码执行的函数
     * @params e 页码数
     * @author 滕威
     */
    fun_PageChange(e) {
      // 获取当前页码
      this.reqPageData.current = e;
      // 获取一览表格数据
      this.fun_GetTableList();
    }
  }
};
